import React from 'react'
import * as FaIcons from 'react-icons/fa' 

export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        icon: <FaIcons.FaHome />
    },    {
        title: 'User',
        path: '/user',
        icon: <FaIcons.FaUser />
    },
    {
        title: 'Vendor',
        path: '/vendor',
        icon: <FaIcons.FaUsers />
    },
    {
        title: 'Cve',
        path: '/cve',
        icon: <FaIcons.FaTasks />
    },
    {
        title: 'Reports',
        path: '/reports',
        icon: <FaIcons.FaBars />
    },
    {
        title: 'Admin',
        path: '/admin',
        icon: <FaIcons.FaCog />
    }
]
