import React from 'react';
import { useState, useEffect } from 'react';
import "../css/threatcart.css"
import * as FaIcons from 'react-icons/fa';
import moment from 'moment';
import LargeSpinner from '../components/LargeSpinner';
import { domainHook } from '../hooks/domain';
import { useUser } from '../hooks/useUser';
import { Button } from 'react-bootstrap';
import SpinnerLoader from '../components/SpinnerLoader';
import SpinnerSmall from '../components/SpinnerSmall';

import {VulnerabilitiesObject} from "../common/types";

const Admin: React.FunctionComponent = () => {
    const {userObject} = useUser();
    const [threatCartData, setThreatCartData] = useState([]);
    const [cisaData, setCisaData] = useState([]);
    const [threatCartApiDone, setThreatCartApiDone] = useState(false);
    const [cisaApiDone, setCisaApiDone] = useState(false);
    const [updatingClick, setUpdatingClick] = useState(false);
    const [updatingDone, setUpdatingDone] = useState(false);
    const [loadingCisaCheck, setLoadingCisaCheck] = useState(false);
    const [loadingThreatCheck, setLoadingThreatCheck] = useState(false);

    const [threatcartCisaDifferenceData, setthreatcartCisaDifferenceData] = useState<VulnerabilitiesObject[]>([]);

    let domain = domainHook();

    const runCheck = async() => {

        setCisaData([]);
        setThreatCartData([]);
        setCisaApiDone(false);
        setThreatCartApiDone(false);
        setLoadingCisaCheck(true);
        setLoadingThreatCheck(true);
        setUpdatingClick(false);

        fetch(`${domain}/Vulnerabilities/GetAllCves` ,{method: 'GET',})
        .then((response) => response.json())
        .then((data) => {
            setThreatCartData(data);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setThreatCartApiDone(true);
            setLoadingThreatCheck(false);
        });

        fetch(`https://v1.slashapi.com/tc/google-sheets/SGx5hbce30/sheet1` ,{method: 'GET'})
        .then((response) => response.json())
        .then((data) => {
            setCisaData(data.data);
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setCisaApiDone(true);
            setLoadingCisaCheck(false);
        });
    }

    const runUpdate = async() => {
        setUpdatingClick(true);
        setUpdatingDone(false);

        let vulArray: VulnerabilitiesObject[] = [];

        cisaData.map((data:any) => {
            if(threatCartData.find((o) => o === data.cveID)){
                //CVE exists in threatcart
            } else {
                let vul = {} as VulnerabilitiesObject;
                vul.cveID = data.cveID;
                vul.dateAdded = data.dateAdded;
                vul.dueDate = data.dueDate;
                vul.product = data.product;
                vul.shortDescription = data.shortDescription;
                vul.vendorProject = data.vendorProject;
                vul.vulnerabilityName = data.vulnerabilityName;
                vul.requiredAction = data.requiredAction;
                vulArray.push(vul);
            }
        });

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ vulArray })
        };

        fetch(`${domain}/Vulnerabilities/InsertVulnerabilities`, {
            method: 'put',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                "vulnerabilityData": vulArray
           })
        }).then((response) => response.json()).finally(() => {
            setUpdatingDone(true);
        });
    }

    return (
        <>
            <div id="vendors" className="tabcontent">
                <h3>Admin</h3>

                <div className="dashboard-container" style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: "75%", height: "330px", border: "3px solid gray", marginTop: "100px", padding: "30px", borderRadius: "30px"}}>
                        <div>Check the CISA Known Exploited Vulnerabilities Catalog</div>
                        <div style={{marginTop: "20px"}}>
                            <Button variant="outline-info" onClick={runCheck}>Run Check</Button>
                        </div>

                        {(loadingCisaCheck && loadingThreatCheck)  ? <SpinnerLoader /> : ""}
                        {(threatCartApiDone && cisaApiDone) && 
                            <div style={{marginTop: "30px", width: "100%"}}>
                                <u><h5 style={{color: "green"}}>Check Complete</h5></u>
                                <div style={{width: "100%", float: "left"}}>
                                    <div style={{float: "left", width: "250px"}}>Cisa CVE Records: </div>
                                    <div style={{fontWeight: "bold"}}> {cisaData.length} Records</div>
                                </div>
                                <div style={{width: "100%"}}>
                                    <div style={{float: "left" , width: "250px"}}>Threat Cart CVE Records: </div>
                                    <div style={{fontWeight: "bold"}}> {threatCartData.length} Records</div>
                                </div>
                                {cisaData.length > threatCartData.length ?
                                <>
                                    <div style={{color: "red", marginTop: "15px", fontWeight: "bold", lineHeight: "15px"}}>{cisaData.length - threatCartData.length} Cisa updates available for Threat Cart</div>
                                    {updatingClick ? "" : <Button style={{lineHeight: "30px"}} onClick={runUpdate} variant="link">Update Now</Button>}
                                    {updatingClick ?
                                    <div style={{marginTop: "20px"}}>
                                        {!updatingDone ?
                                        <><SpinnerSmall /><div style={{float:"left", marginLeft: "10px"}}>Updating {cisaData.length - threatCartData.length > 100 ? "100" : cisaData.length - threatCartData.length} Records</div></>
                                        :
                                        <><FaIcons.FaCheckSquare style={{float: "left", color: "green"}} /><div style={{float:"left", marginLeft: "5px", color: "green"}}>{cisaData.length - threatCartData.length > 100 ? "100" : cisaData.length - threatCartData.length} Records have been Updated</div></>
                                        }
                                    </div>
                                    :
                                    <div></div>
                                    }
                                </>
                                :
                                <div style={{color: "green", marginTop: "15px", fontWeight: "bold"}}>Threat Cart records are up to date with CISA</div>
                                }
                            </div>
                        }
                        
                    </div>

    
                </div>
            </div>
        </>
    )
}

export default Admin
