import React from 'react'
import {useState} from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Sidebar from './components/Sidebar'

import Home from './pages/Home';
import User from './pages/User';
import Vendor from './pages/Vendor';
import Cve from './pages/Cve';
import Reports from './pages/Reports';
import Admin from './pages/Admin';


const App: React.FunctionComponent = () => {

  return (
    <>
      <Router>
        <Sidebar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/user' element={<User />} />     
          <Route path='/vendor' element={<Vendor />} />            
          <Route path='/cve' element={<Cve />} />   
          <Route path='/reports' element={<Reports />} />  
          <Route path='/admin' element={<Admin />} />  
        </Routes>
      </Router> 
    </>
  )
}

export default App
