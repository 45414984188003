import React, {useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

const Spinner: React.FunctionComponent = () => {
    return (
        <button style={{float: 'right', marginRight:'10px'}} className="btn btn-primary" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;Loading...
        </button>
    )
}

export default Spinner
