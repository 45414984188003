import {useState} from 'react';

export interface UserObject {
    userID?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    createdDate?: string;
    lastLogin?: string;
    loginExpiry: string;
    isAdmin: string;
}

function initiaizeUser(){
    const userData = localStorage.getItem("userObject");
    return userData ? JSON.parse(userData) : null;
}

export function useUser () {
    const [userObject, setUserObject] = useState<UserObject | null>(initiaizeUser);

    return {
        userObject
    };
}