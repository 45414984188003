import React, {useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

const LargeSpinner: React.FunctionComponent = () => {
    return (
        <div className="d-flex justify-content-center" style={{zIndex: "10", position: "absolute", width: "100%", marginTop: "100px"}}>
        <div className="spinner-border" style={{width: '6rem', height: '6rem'}} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        </div>
    )
}

export default LargeSpinner
