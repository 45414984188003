import React, {useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

const SpinnerSmall: React.FunctionComponent = () => {
    return (
        <div className="d-flex" style={{float:"left"}}>
            <div className="spinner-border" style={{width: '1.8rem', height: '1.8rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default SpinnerSmall
