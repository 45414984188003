import React, {useEffect} from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa' 
import { SidebarData } from './SidebarData'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import Spinner from './Spinner';
import logo from '../images/threatcart-logo-icon.png';
import { domainHook } from '../hooks/domain'

const Navbar = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 3.5rem;
    background-color: #000080;
`

const MenuIconOpen = styled(Link)`
    display: flex;
    justify-content: start;
    font-size: 1.5rem;
    margin-left: 2rem;
    color: #ffffff;
`

const MenuIconClose = styled(Link)`
    display: flex;
    justify-content: end;
    font-size: 1.5rem;
    margin-top: 0.75rem;
    margin-right: 1rem;
    color: #ffffff;
`

const SidebarMenu = styled.div<{close: boolean}>`
    width: 250px;
    height: 100vh;
    background-color: #000080;
    position: fixed;
    top: 0;
    left: ${({ close}) => close ? '0' : '-100%'};
    transition: .6s;
`

const MenuItems = styled.li`
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 90px;
    padding: 1rem 0 1.25rem;
`

const MenuItemLinks = styled(Link)`
    display: flex;
    align-items: center;
    padding: 0 2rem;
    font-size: 20px;
    text-decoration: none;
    color: #ffffff;

    &:hover {
        background-color: #ffffff;
        color: #000080;
        width: 100%;
        height: 45px;
        text-align: center;
        border-radius: 5px;
        margin: 0 2rem;
    }
`

export interface UserObject {
    userID: string;
    firstName: string;
    lastName: number;
}

const initialState = {
        userID: '',
        firstName: '',
        lastName: ''
  }

const Sidebar: React.FunctionComponent = () => {
    const [close, setClose] = useState(false);
    const showSidebar = () => setClose(!close);
    const navigate = useNavigate();
    const [userObject, setUserObject] = useState<UserObject | null>(null);
    const [userInput, setUserInput] = useState(""); 
    const [loading, setLoading] = useState(false);

    let domain = domainHook();

    const userLogin = (userInput: string) => {
        setLoading(true);
            //fetch(`https://localhost:7030/User/Login?email=${userInput}`, {
            fetch(`${domain}/User/Login?email=${userInput}` ,{method: 'GET',})
              .then((response) => response.json())
              .then((data) => {

                if(data.userID != 0){
                    localStorage.setItem("userObject", JSON.stringify(data));
                    const dataTest = localStorage.getItem("userObject");
                    setUserObject(JSON.parse(dataTest == null ? '{"userID": 0}' : dataTest));
                    navigate('/user');
                } else {
                    setUserObject(JSON.parse('{"userID": 0}'));
                }
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
      }

    useEffect(() => {
        const dataTest = localStorage.getItem("userObject");
        setUserObject(JSON.parse(dataTest == null ? '{"userID": 0}' : dataTest));
    }, []);

    const LoginButtonClick = (props:any)=>{ 
        if(props.target.innerText === "Login"){
            if(userInput == ""){
                setUserObject(JSON.parse('{"userID": 0}'));
                navigate('/');
            } else {
                userLogin(userInput);
            }
        } else {
            navigate('/');
            setUserObject(JSON.parse('{"userID": 0}'));
            localStorage.removeItem("userObject");
            props.target.innerText = "";
            setUserInput("");
        }
    }

    return (
        <>
            <Navbar>
                <MenuIconOpen to="#" style={{zIndex:"10"}} onClick={showSidebar}>
                    <FaIcons.FaBars />
                </MenuIconOpen>
                <div style={{color: "white", width: "100%", position: "absolute", textAlign: "center", fontSize: "30px"}}>
                <div style={{position: "relative", left: "43%", width: "20%"}}><img src={logo} alt="Logo" style={{float: 'left', height:"44px"}} />THREATCART</div>
                </div>
                <div style={{color: 'white', width: '275px', marginLeft: 'auto', marginRight: '0', zIndex: "8"}}>
                {loading ? <Spinner /> : ""}
                {userObject?.userID == '0' && !loading ? <input name="email" autoComplete='on' value={userInput} onChange={(e) => {setUserInput(e.target.value)}} style={{width: '200px', height: '30px', float: 'left'}} type='email' className="form-control" placeholder="Email" aria-label="Username" aria-describedby="basic-addon1" required/> : null}
                {userObject?.userID == '0' && !loading ? <Button variant="outline-light" onClick={(e)=>{LoginButtonClick(e)}} style={{height:'30px', marginLeft: '5px', lineHeight: '15px'}}>Login</Button> : null}
                
                {userObject?.userID != '0' ? <Button style={{height:'30px', lineHeight: '15px', marginRight: '5px', float: 'right'}} variant="primary" onClick={(e)=>{LoginButtonClick(e)}}>Logout</Button>: null}
                {userObject?.userID != '0' ? <div style={{height: '30px', lineHeight: '30px', float: 'right', marginRight: '10px'}}>{userObject?.firstName}</div> : null}
                </div>
            </Navbar>
            
            <SidebarMenu style={{zIndex:'10'}} close={close}>
                <MenuIconClose to="#" onClick={showSidebar}>
                    <FaIcons.FaTimes />
                </MenuIconClose>

                {SidebarData.map((item, index) => {
                    if((userObject?.userID != '0') || (item.title == "Home")){
                        return (
                            <MenuItems key={index}>
                                <MenuItemLinks onClick={showSidebar} to={item.path}>
                                    {item.icon}
                                    <span style={{marginLeft: '16px'}}>{item.title}</span>
                                </MenuItemLinks>
                            </MenuItems>
                        )
                    }
                })}
            </SidebarMenu>
        </>
    )
}

export default Sidebar
