import React from 'react'
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import logo from '../images/threatcart-logo.png';
import aboutRight from '../images/about_right.jpg';
import aboutImg from '../images/about_img.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../style.css'

const Home: React.FunctionComponent = () => {
    return (
        <>
            <div id="about" style={{padding: '130px 0 0px 0'}} className="about">
                <div className="container" style={{maxWidth: '1140px'}}>
                    <div className="row ">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="titlepage" style={{marginTop:'-100px'}}>
                                        
                                        <div className='home-text-container'>
                                        <img src={logo} alt="Logo" style={{float: 'left', height:"130px"}} />
                                            {/*<div style={{marginTop: '56px'}}>THREAT CART</div>*/}
                                        </div>
                                        <p>Our application brings together the effectiveness of organizing and tracking the response to a zero day and high profile threats for your environment.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="about_img">
                                        <figure><img src={aboutImg} alt="AboutImg" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="about_right">
                                <figure><img src={aboutRight} style={{width: '98%'}} alt="AboutRight" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
