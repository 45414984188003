import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/threatcart.css";
import "../css/gridview.css";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import * as FaIcons from 'react-icons/fa';
import { domainHook } from '../hooks/domain';
import { useUser } from '../hooks/useUser';

export interface UserCvesObject {
    [key: string]: string;
    userID: string;
    cveID: string;
    vendorProduct: string;
    cveWorked: string;
    networkFirewallStatus: string;
    networkFirewallComment: string;
    networkIDSIPSStatus: string;
    networkIDSIPSComment: string;
    networkWebStatus: string;
    networkWebComment: string;
    networkEmailStatus: string;
    networkEmailComment: string;
    endpointEDRStatus: string;
    endpointEDRComment: string;
    endpointIPSStatus: string;
    endpointIPSComment: string;
    endpointFirewallStatus: string;
    endpointFirewallComment: string;
    endpointWebStatus: string;
    endpointWebComment: string;
    appSIEMStatus: string;
    appSIEMComment: string;
    appEDRStatus: string;
    appEDRComment: string;
    appMitigationConfigStatus: string;
    appMitigationConfigComment: string;
    appMitigationPatchingStatus: string;
    appMitigationPatchingComment: string;
}

const Reports: React.FunctionComponent = () => {
    const {userObject} = useUser();
    const [userVendorCvesObject, setUserVendorCvesObject] = useState<UserCvesObject[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [radioValue, setRadioValue] = useState("all");

    let domain = domainHook();

    const getUserVendorCves = async(userID: string) => {
        //setLoadingUser(true);
            fetch(`${domain}/Cve/GetVulnerabilitiesByUserId?userID=${userID}` ,{method: 'GET',})
                .then((response) => response.json())
                .then((data) => {
                    setUserVendorCvesObject(data);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                //setLoadingUser(false);
            });
    }

    // Filter data based on search term
    const filteredData = userVendorCvesObject.filter(item => {
        console.log(radioValue);
        if(radioValue === "all"){
            if(item.cveID.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.vendorProduct.toLowerCase().includes(searchTerm.toLowerCase())){
                    return true;
            }

        } else {
            if(item.cveWorked === "true" && (
                item.cveID.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.vendorProduct.toLowerCase().includes(searchTerm.toLowerCase()))){
                return true;
            }   
        }
    });

    function getCatagoryTotal(cve : UserCvesObject, catagory: string) {

        let total = 0;
        if(catagory === "network" || catagory === "total") {
            total += getProtectionLevelToInt(cve.networkFirewallStatus);
            total += getProtectionLevelToInt(cve.networkEmailStatus);
            total += getProtectionLevelToInt(cve.networkIDSIPSStatus);
            total += getProtectionLevelToInt(cve.networkWebStatus);
        } 
        if (catagory === "endpoint" || catagory === "total") {
            total += getProtectionLevelToInt(cve.endpointEDRStatus);
            total += getProtectionLevelToInt(cve.endpointFirewallStatus);
            total += getProtectionLevelToInt(cve.endpointIPSStatus);
            total += getProtectionLevelToInt(cve.endpointWebStatus);
        } 
        if (catagory === "app" || catagory === "total") {
            total += getProtectionLevelToInt(cve.appEDRStatus);
            total += getProtectionLevelToInt(cve.appMitigationConfigStatus);
            total += getProtectionLevelToInt(cve.appMitigationPatchingStatus);
            total += getProtectionLevelToInt(cve.appSIEMStatus);
        }
        if(catagory === "total"){
            return Math.round(total/1200*100);
        } else {
            return Math.round(total/400*100);
        }
    }

    function getProtectionLevelToInt(protectionLevel: string) {
        if (protectionLevel == "doesnotapply") {return 100;}
        else if (protectionLevel == "moderateprotection") {return 50;}
        else if (protectionLevel == "advancedprotection") {return 100;}
        else { return 0;} 
    }

    function setColumnCSS(value: any) {
        if (value < 20) {
            return "gridviewcellred";
        } else if (value > 80) {
            return "gridviewcellgreen";
        } else {
            return "gridviewcellorange";
        }
    }

    useEffect(() => {
        getUserVendorCves(userObject?.userID!);
    },[]);

    const radios = [
        { name: 'All Cves', value: 'all' },
        { name: 'Cves Worked On', value: 'worked' }
      ];

    return (
            <>
                <div id="vendors" className="tabcontent">
                    <h3>Reports</h3>

                    <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                       className="mb-4 p-2 border border-gray-300 rounded" style={{width: "250px"}} />

                    <ButtonGroup>
                        {radios.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={idx % 2 ? 'outline-info' : 'outline-info'}
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={(e) => setRadioValue(e.currentTarget.value)}
                        >
                            {radio.name}
                        </ToggleButton>
                        ))}
                    </ButtonGroup>


                    <table id="GridView1" className="mydatagrid GVFixedHeader" style={{margin:"auto", backgroundColor:"white", marginBottom:"30px", width:"90%", textAlign:"center"}} border={1}>
                        <tr className="tableHeader" id="tableHeader" style={{backgroundColor: "slategray", color:"white"}}>
                            <th className="savecolumn">Vendor Product</th>
                            <th className="active">CVE ID</th>
                            <th className="active">Worked On</th>
                            <th className="technology">Network Percentage</th>
                            <th className="cellfifteen">End Point Percentage</th>
                            <th className="cellfifteen">App Percentage</th>
                            <th className="cellfifteen">TOTAL</th>
                        </tr>

                        {filteredData == null ? "" : filteredData?.map((cve, index) => {
                            if(radioValue){

                                return (
                                    <tr key={index}>
                                    <td>{cve.vendorProduct}<br /></td>
                                    <td>{cve.cveID}<br /></td>
                                    <td style={{color:'green'}}>{cve.cveWorked == "true" ? <FaIcons.FaCheck /> : ""}</td>
                                    <td className={setColumnCSS(getCatagoryTotal(cve, "network"))}>{getCatagoryTotal(cve, "network")} %<br /></td>
                                    <td className={setColumnCSS(getCatagoryTotal(cve, "endpoint"))}>{getCatagoryTotal(cve, "endpoint")} %<br /></td>
                                    <td className={setColumnCSS(getCatagoryTotal(cve, "app"))}>{getCatagoryTotal(cve, "app")} %<br /></td>
                                    <td className={setColumnCSS(getCatagoryTotal(cve, "total"))}>{getCatagoryTotal(cve, "total")} %<br /></td>
                                    </tr>
                                );

                            } else {
                                return "Test"
                            }

                        })}

                    </table>
                </div>
            </>
    )
}

export default Reports
