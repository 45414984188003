import React, {useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

const SpinnerLoader: React.FunctionComponent = () => {
    return (
        <div className="d-flex justify-content-center">
        <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        </div>
    )
}

export default SpinnerLoader
