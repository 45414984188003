import React, {useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import * as FaIcons from 'react-icons/fa';
import { useParams } from 'react-router-dom';

export interface UserCvesObject {
    //[key: string]: string;
    userID: string;
    cveID: string;
    vendorProduct: string;
    networkFirewallStatus: string;
    networkFirewallComment: string;
    networkIDSIPSStatus: string;
    networkIDSIPSComment: string;
    networkWebStatus: string;
    networkWebComment: string;
    networkEmailStatus: string;
    networkEmailComment: string;
    endpointEDRStatus: string;
    endpointEDRComment: string;
    endpointIPSStatus: string;
    endpointIPSComment: string;
    endpointFirewallStatus: string;
    endpointFirewallComment: string;
    endpointWebStatus: string;
    endpointWebComment: string;
    appSIEMStatus: string;
    appSIEMComment: string;
    appEDRStatus: string;
    appEDRComment: string;
    appMitigationConfigStatus: string;
    appMitigationConfigComment: string;
    appMitigationPatchingStatus: string;
    appMitigationPatchingComment: string;
}

interface IMyProps {
    item: string,
    cveObject: UserCvesObject,
}

const CveProtectionDetails: React.FunctionComponent<IMyProps> = (props: IMyProps) => {

    function cveKeyMapping(key : string, cveItem: any){
        if(key === "networkFirewallStatus"){
            return cveItem.networkFirewallStatus;
        } else if (key === "networkIDSIPSStatus"){
            return cveItem.networkIDSIPSStatus;
        } else if (key === "networkWebStatus"){
            return cveItem.networkWebStatus;
        } else if (key === "networkEmailStatus"){
            return (cveItem.networkEmailStatus);
        } else if (key === "endpointEDRStatus"){
            return (cveItem.endpointEDRStatus);
        } else if (key === "endpointIPSStatus"){
            return (cveItem.endpointIPSStatus);
        } else if (key === "endpointFirewallStatus"){
            return (cveItem.endpointFirewallStatus);
        } else if (key === "endpointWebStatus"){
            return (cveItem.endpointWebStatus);
        } else if (key === "appSIEMStatus"){
            return (cveItem.appSIEMStatus);
        } else if (key === "appEDRStatus"){
            return (cveItem.appEDRStatus);
        } else if (key === "appMitigationConfigStatus"){
            return (cveItem.appMitigationConfigStatus);
        } else if (key === "appMitigationPatchingStatus"){
            return (cveItem.appMitigationPatchingStatus);
        } 
    }

    const formatText = (protectionLevel: string) =>{
        if (protectionLevel == "advancedprotection") {
            return "Advanced Protection";
        } else if (protectionLevel == "moderateprotection") {
            return "Moderate Protection";
        } else if (protectionLevel == "noprotection") {
            return "No Protection";
        } else if (protectionLevel == "doesnotapply") {
            return "Does Not Apply";
        } else {
            return "Unfulfilled";
        }
    }

    function cssColor(cveItem : string){
        if (cveItem == "advancedprotection") {
            return "green";
        } else if (cveItem == "moderateprotection") {
            return "yellow";
        } else if (cveItem == "noprotection") {
            return "pink";
        } else if (cveItem == "doesnotapply") {
            return "black";
        } else {
            return "grey";
        }
    }

    function cveItemFAIcon(key : string){
        if(key === "networkFirewallStatus"){
            return <FaIcons.FaShieldAlt />;
        } else if (key === "networkIDSIPSStatus"){
            return <FaIcons.FaChartBar />;
        } else if (key === "networkWebStatus"){
            return <FaIcons.FaNetworkWired />;
        } else if (key === "networkEmailStatus"){
            return <FaIcons.FaEnvelope />;
        } else if (key === "endpointEDRStatus"){
            return <FaIcons.FaUserShield />;
        } else if (key === "endpointIPSStatus"){
            return <FaIcons.FaStream />;
        } else if (key === "endpointFirewallStatus"){
            return <FaIcons.FaShieldAlt />;
        } else if (key === "endpointWebStatus"){
            return <FaIcons.FaGlobe />;
        } else if (key === "appSIEMStatus"){
            return <FaIcons.FaChartArea />;
        } else if (key === "appEDRStatus"){
            return <FaIcons.FaLaptop />;
        } else if (key === "appMitigationConfigStatus"){
            return <FaIcons.FaClone />;
        } else if (key === "appMitigationPatchingStatus"){
            return <FaIcons.FaClone />;
        } 
    }

    function itemTextFormat(item: string){
        if(item.includes("network")){
            item = item.replace("network","Network ");
        } else if (item.includes("endpoint")) {
            item = item.replace("endpoint","Endpoint ");
        } else if (item.includes("app")) {
            item = item.replace("app","App ");
        }
        return item.replace("Status","");
    }

    const updateCVELevel = async(cveItem : any, protectionLevel : any) => {
        
        console.log(cveItem + ":" + protectionLevel);
        cveItem = protectionLevel;

    }

    return (


            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col-8">
                        <h4 className={`f-w-600 text-c-${cssColor(cveKeyMapping(props.item, props.cveObject))}`}>{formatText(cveKeyMapping(props.item, props.cveObject))}</h4>
                        <h6 className="text-muted m-b-0">{itemTextFormat(props.item)}</h6>
                    </div>
                    <div className="col-4 text-right">
                        <i className="fas fa-shield-alt" style={{fontSize:'24px'}}>{cveItemFAIcon(props.item)}</i>
                    </div>
                </div>
            </div>


    )
}

export default CveProtectionDetails
